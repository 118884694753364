/** @format */

import React from "react";
import ReactTypingEffect from "react-typing-effect";
import CountDown from "../utils/Countdown";

function Hero() {
  const partner = [
    {
      imgUrl: "assets/pinksale.png",
      link: "",
    },
    { imgUrl: "assets/cyber.png", link: "https://github.com/cyberscope-io/audits/blob/main/ai2/audit.pdf" },
    {
      imgUrl: "assets/dex.png",
      link: "https://www.dexview.com/bsc/0xdB866990a72f0Ee255458a61E215b5cD509431f9",
    },
    {
      imgUrl: "assets/pcs.png",
      link: "https://pancakeswap.finance/swap?outputCurrency=0xdB866990a72f0Ee255458a61E215b5cD509431f9",
    },
  ];
  return (
    <div className='container-fluid hero-bg' id='home'>
      <div className='row'>
        <div
          className='col-md-12'
          style={{ paddingLeft: "0", paddingRight: "0" }}>
          <div className='overlay'></div>
          <video playsInline autoPlay muted loop className='m-auto'>
            <source
              src={process.env.REACT_APP_S3_URL + "/OhQm0O5.mp4"}
              type='video/mp4'
            />
            Your browser does not support the video tag.
          </video>
          <div className='hero-content text-center m-auto'>
            {/* <CountDown /> */}
            <h2>
              Hello!{" "}
              <span style={{ color: "#0063F7" }}>
                {" "}
                <ReactTypingEffect text={["Tune in to explore more…"]} />
              </span>
            </h2>
            <h1 data-aos='fade-up'>
              Revolutionising the Crypto industry <br /> with Artificial
              intelligence
            </h1>
            <p data-aos='fade-up'>
              AI Square, or AI.2, is your advanced guide to the world of crypto!
              Based on the cutting edge AI tech, the AI square bots facilitates
              its users through different crypto features for a smoother
              transition. Your AI guru will guide you through advanced features
              like, AI NFT creation, AI trading assistance, and much more.
            </p>
            {/* <a
              href='https://www.pinksale.finance/launchpad/0xAF76685b98202E0E3b7E5686aCad31Fd1282F082?chain=BSC'
              target='_blank' 
              rel='noopener noreferrer'>
              <button className="mx-3 btns">
                <img
                  src='https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png'
                  style={{ width: 28, display: "inline-block" }}
                  alt=''
                />{" "}
                <span>Join on Pinksale</span>
              </button>
            </a> */}
            <a
              href='https://squareai.gitbook.io/square-ai/'
              target='_blank'
              rel='noopener noreferrer'>
              <button>
                <span> Explore AI Square</span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          {partner.map((v, i) => {
            return (
              <div className='col-md-3 col-6 m-auto position-relative' key={i}>
                <a href={v.link} target='_blank' rel='noopener noreferrer'>
                  <img src={v.imgUrl} className='w-50 d-block m-auto' alt='' />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Hero;
