/** @format */

import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Base from "./components/layouts/Base";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import ReactGA from "react-ga";
import { useEffect } from "react";
ReactGA.initialize("UA-258508156-1");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  return (
    <>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
          </Routes>
        </Base>
      </BrowserRouter>
    </>
  );
}

export default App;
