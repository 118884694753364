/** @format */

import React from "react";

function Section2() {
  return (
    <div className='container-fluid mt-5 sec2'>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <video playsInline autoPlay muted loop className='m-auto res-video1'>
            <source
              src={process.env.REACT_APP_S3_URL + "/ezbvshI.mp4"}
              type='video/mp4'
            />
            Your browser does not support the video tag.
          </video>
          <div className='content' data-aos="flip-up">
            <h1>why do you need AI? </h1>
            <p>
              AI2 represents the future of investment. With the amalgamation of
              cutting edge AI tech and Crypto, the AI square, encloses tardy
              technologies and tools that can be used to analyse new investment
              possibilities and generate better investment decisions.
            </p>
            <button className='sec1-btn mt-5'>
              <span>whitepaper</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
