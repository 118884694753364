/** @format */

import React from "react";

const Timeline = () => {
  return (
    <>
      <section
        id='timeline'
        className='section container-fluid section-roadmap   mb-3'>
        <div className='row mt-5 pt-5 roadmap'>
          <div
            className='col-md-12 text-center'
            data-aos='fade-up'
            data-aos-anchor-placement='bottom-bottom'>
            <img src='assets/product.svg' alt='' />
            <h1>roadmap</h1>
            <p>it’s updating</p>
          </div>
        </div>
        <div
          id='roadmap-slider'
          className='slider mb-3'
          style={{ cursor: "grab" }}>
          <div className='slider-inner'>
            <div className='roadmap-item  in-progress' data-status='PHASE:01'>
              <span className='item-title'>Q1 2023</span>
              <div className='item-tasks'>
                <div className='item-task f16 in-progress'>
                  Key advisors commence development
                </div>
                <div className='item-task f16 in-progress mt-3'>Website creation</div>
                <div className='item-task f16 in-progress mt-3'>
                  {" "}
                  Whitepaper release
                </div>
                <div className='item-task f16 in-progress mt-3'>
                  {" "}
                  Social media presence
                </div>
                <div className='item-task f16 in-progress mt-3'>
                  {" "}
                  Smart contract audit
                </div>
              
                <div className='item-task f16 in-progress mt-3'>
                  {" "}
                  Community reward/Giveaway
                </div>
                <div className='item-task f16 in-progress mt-3'>
                  {" "}
                  Cyberscope Audit
                </div>
                <div className='item-task f16 in-progress mt-3'>
                  {" "}
                  Strategic Partnerships
                </div>
              </div>
              <div className='matrix-bg' />
            </div>
            <div className='roadmap-item wait-for-it' data-status='PHASE:02'>
              <span className='item-title'>Q2 2023</span>
              <div className='item-tasks'>
                <div className='item-task f16 wait-for-it'>
                  {" "}
                  5% initial sale to exclusive VC’s/investors
                </div>
                <div className='item-task  mt-3 f16 wait-for-it'>
                  {" "}
                  Staking platform launch
                </div>
                <div className='item-task f16 mt-3 wait-for-it'>
                  {" "}
                  NFT minting
                </div>
                <div className='item-task f16 mt-3 wait-for-it'>
                  {" "}
                  Launch on pancakeswap
                </div>
                <div className='item-task f16 mt-3 wait-for-it'>
                  {" "}
                  Key influencers onboarding / AMA
                </div>
                <div className='item-task f16 mt-3 wait-for-it'>
                  {" "}
                  Coin market cap and coin market listing
                </div>
                <div className='item-task f16 mt-3 wait-for-it'>
                  {" "}
                  CEX listing
                </div>

                <div className='item-task f16 mt-3 wait-for-it'>
                  {" "}
                  Strategic partnership with key partners and leading brands
                </div>
              </div>
              <div className='matrix-bg' />
            </div>
            <div className='roadmap-item wait-for-it' data-status='PHASE:03'>
              <span className='item-title'>Q3, 2023</span>
              <div className='item-tasks'>
                <div className='item-task wait-for-it f16 wait-for-it mt-3'>
                  {" "}
                  RPG metaverse game on testnet
                </div>
                <div className='item-task wait-for-it f16 wait-for-it mt-3'>
                  {" "}
                  Governance DAO
                </div>
                <div className='item-task wait-for-it f16 wait-for-it mt-3'>
                  {" "}
                  Gaming Tournaments
                </div>
                <div className='item-task wait-for-it  f16 wait-for-it mt-3'>
                  {" "}
                  Coingecko/Coinmarketcap listing
                </div>
                <div className='item-task wait-for-it f16 wait-for-it mt-3'>
                  {" "}
                  Deploy Contracts on Polygon blockchain
                </div>
                <div className='item-task wait-for-it f16 wait-for-it mt-3'>
                  {" "}
                  Collaboration & Partnership
                </div>
                <div className='item-task wait-for-it f16 wait-for-it mt-3'>
                  {" "}
                  NFTs Airdrop for presale participants
                </div>
                <div className='item-task wait-for-it f16 wait-for-it mt-3'>
                  {" "}
                  Binance NFTs Marketplace magic box listing
                </div>

                <div className='item-task wait-for-it f16 wait-for-it mt-3'>
                  {" "}
                  Pancakeswap NFTs listing
                </div>
              </div>
              <div className='matrix-bg' />
            </div>
            <div className='roadmap-item wait-for-it' data-status='PHASE:04'>
              <span className='item-title'>Q4 2023</span>
              <div className='item-tasks'>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Mobile app development
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Launch of test net game for cross chain
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Live NFTs marketplace on testnet
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Deploying of AI-Square NFT platform on main net
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  NFTs Mint for 3rd round
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Mobile Application
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  RPG metaverse game on mainnet
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  P2E Game on Testnet
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  VIP Membership
                </div>
              </div>
              <div className='matrix-bg' />
            </div>
            <div className='roadmap-item wait-for-it' data-status='PHASE:05'>
              <span className='item-title'>Q1 2024</span>
              <div className='item-tasks'>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Community attestation
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  NFTs Mint for 3rd round
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Reward on staking NFT platform
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Media collaboration
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Research and development
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  Unlocking lottery system (Lottery event participation that
                  rewards AI-Square native token).
                </div>
                <div className='item-task f16 wait-for-it mt-3 wait-for-it'>
                  {" "}
                  P2E Game on Mainnet
                </div>
              </div>
              <div className='matrix-bg' />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Timeline;
