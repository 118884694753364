/** @format */

import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaTwitter } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";

function About() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className='container-fluid roadmap mt-5  mb-5 pb-5 '>
      <div className='row'>
        <div className='col-md-12 text-center pt-5'>
          <img src='assets/6.svg' alt='' />
          <h1 className='mt-4 mb-4'>more about ai square</h1>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <Swiper
            slidesPerView={2}
            centeredSlides={false}
            slidesPerGroupSkip={3}
            grabCursor={true}
            keyboard={{
              enabled: true,
            }}
            breakpoints={{
              769: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
            scrollbar={true}
            navigation={false}
            pagination={{
              clickable: true,
            }}
            modules={[Keyboard, Scrollbar, Navigation, Pagination]}
            className='mySwiper'>
            <SwiperSlide>
              <div className='caro-item'>
                <img src='assets/checkmark.svg' alt='' />{" "}
                <strong className='text-light mx-3 fs-5'> AI Staking</strong>
                <p className='pt-2'>
                  AI staking pools help users find appropriate staking pools
                  across multiple LPs that offers the locking periods and
                  returns according to the preferences of the users.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='caro-item'>
                <img src='assets/checkmark.svg' alt='' />{" "}
                <strong className='text-light mx-3 fs-5'>NFT Bazaar</strong>
                <p className='pt-2'>
                  You may use this tool to purchase, sell, and exchange
                  fantastic digital assets in the form of NFTs. And don't worry:
                  we've got you covered We've made everything super-easy and
                  straightforward to use so you don't have to worry about not
                  finding what you're looking for or not getting it delivered to
                  you.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='caro-item'>
                <img src='assets/checkmark.svg' alt='' />
                  <strong className='text-light mx-3 fs-5'>Social Sync</strong>
                <p className='pt-2'>
                  {" "}
                  The AI NFT Creator's Social Sync function encourages users to
                  share their NFT collections with a larger audience and
                  increase the visibility of their works. This raises public
                  knowledge of NFTs, which leads to additional possibilities for
                  them to be swapped and exchanged.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {/* <div style={{ position: "relative" }} className='aabout-bg-shadow'> */}
      {/* <video playsInline autoPlay muted loop className='m-auto res-video1'>
          <source
            src={process.env.REACT_APP_S3_URL + "/pC5P1Gx.mp4"}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video> */}

      {/* <div className='about-content'>
          <div className='row mt-5 mb-5 m-auto about-video'>
            <div className='col-md-1'></div>
            <div className='row '>
              <div className='col-md-4'>
                <div
                  className='article-bg p-3 mt-4'
                  data-aos='fade-down'
                  data-aos-duration='1500'>
                  <p>
                    “Looking for a status page? I recommend{" "}
                    <span>@AiSquare.</span> Perfect support, answered my dms in
                    a couple of minutes, and it's the first actual cool looking
                    status page which allows custom domains (on the free plan
                    😱) haven't actually tried it, but it looks good so far
                  </p>
                  <FaTwitter style={{ float: "right", color: "#7a74ff" }} />
                  <div className='d-flex align-items-start'>
                    <img src='assets/ellipse.svg' alt='' />
                    <div className='mx-2'>
                      <h2 className='pb-0 mb-0'>Jhon</h2>
                      <p className='pt-0 mt-0'>@jhonepei</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default About;
