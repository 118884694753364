import React from "react";

function Tokonomics() {
  return (
    <div className="container-fluid toko pt-5 pb-5">
      <div className="row">
        <h1 className="text-center mb-5 mt-5" style={{fontSize: '48px'}}>Tokonomics</h1>
        <div className="col-md-4 res-col">
            <div className="toko-textEnd">
            <h1>   <span className="text-warning">25%</span>   Public sale <img src="assets/icon3.svg" style={{width:28}} alt="" /></h1>

            </div>
            <div className="mt-5 toko-textEnd">
            <h1>   <span className="text-warning">15%</span>  Liquidity <img src="assets/icon3.svg" style={{width:28}} alt="" /></h1>

            </div>

            <div className="mt-5 toko-textEnd">
        
          <h1>   <span className="text-warning">13%</span>  Staking reward pool <img src="assets/icon3.svg" style={{width:28}} alt="" /></h1>
        
            </div>
            <div className="mt-5 toko-textEnd">
        
        <h1>   <span className="text-warning">12%</span>  Team funds for development <img src="assets/icon3.svg" style={{width:28}} alt="" /></h1>
      
          </div>
          <div className="mt-5 toko-textEnd">
        
        <h1>   <span className="text-warning">13%</span>  Marketing funds <img src="assets/icon3.svg" style={{width:28}} alt="" /></h1>
      
          </div>
            
        </div>
        <div className="col-md-4 text-center m-auto mt-5">
           <div className="toko-video-bg">
            <video data-aos="zoom-out-up"
             playsInline autoPlay muted loop
              className="m-auto"
              
              style={{ width: "250px", height: "250px" }}
            >
              <source src={process.env.REACT_APP_S3_URL +"/p5iK53A.mp4"} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="col-md-4">
     
        <div className="text-start">
            <h1><img src="assets/icon3.svg" style={{width:28}} alt="" />   <span className="text-warning">5%</span>   Bounty reward </h1>

            </div>
           

            <div className="mt-5 text-start">
        
          <h1><img src="assets/icon3.svg" style={{width:28}} alt="" />   <span className="text-warning">3%</span>  Airdrop funds </h1>
        
            </div>
            <div className="mt-5 text-start">
        
        <h1> <img src="assets/icon3.svg" style={{width:28}} alt="" />  <span className="text-warning">6%</span>   Treasury funds </h1>
      
          </div>
          <div className="mt-5 text-start">
        
        <h1><img src="assets/icon3.svg" style={{width:28}} alt="" />   <span className="text-warning">4%</span>  Advisory board </h1>
      
          </div>
          <div className="mt-5 text-start">
        
        <h1><img src="assets/icon3.svg" style={{width:28}} alt="" />   <span className="text-warning">4%</span>  Strategic partnerships </h1>
      
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokonomics;
