import React from 'react'
import {IoArrowRedoCircleSharp} from 'react-icons/io5'
function Roadmap() {
  return (
    <div className="container mt-5 roadmap mb-5">
        <div className="row mt-5 pt-5">
            <div className="col-md-12 text-center">
                <img src="assets/product.svg" alt="" />
                <h1>roadmap</h1>
                <p>it’s updating</p>
            </div>
        </div>

        <div className="row mt-5 pt-5 ">
            <div className="col-md-4">
                <div className="roadmap-box p-3">
                    <div className="number">
                        <h1>1</h1>
                    </div>
                    <div>
                        <h1>phase</h1>
                        <p className='mt-5'><IoArrowRedoCircleSharp color='#06C270' size={24}/> Realise on Pinksale</p>
                        <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> AI NFT Image Creator</p>
                        <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> SAFU + AUDIO + KYC</p>
                        <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> Token Airdrop</p>
                        <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> NFT Airdrop</p>
                        <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> 5k Telegram</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="roadmap-box p-3">
                <div className="number">
                        <h1>2</h1>
                    </div>
                    <h1>phase</h1>
                    <p className='mt-5'><IoArrowRedoCircleSharp color='#06C270' size={24}/> Realise on Pinksale</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> AI NFT Image Creator</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> SAFU + AUDIO + KYC</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> Token Airdrop</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> NFT Airdrop</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> 5k Telegram</p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="roadmap-box p-3">
                <div className="number">
                        <h1>3</h1>
                    </div>
                    <h1>phase</h1>
                    <p className='mt-5'><IoArrowRedoCircleSharp color='#06C270' size={24}/> Realise on Pinksale</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> AI NFT Image Creator</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> SAFU + AUDIO + KYC</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> Token Airdrop</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> NFT Airdrop</p>
                    <p><IoArrowRedoCircleSharp color='#06C270' size={24}/> 5k Telegram</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Roadmap