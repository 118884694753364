/** @format */

import React from "react";
import { BsArrowRight } from "react-icons/bs";

function Section1() {
  const data = [
    {
      img: "assets/card.svg",
      anchor: "Live",
      heading: "AI crypto insights",
      text: "AI crypto insight feature is your one-stop to the crypto world, all you need to know or learn is provided to you by your AI crypto insight provider.",
      effect:"fade-up"
    },
    {
      img: "assets/card1.svg",
      anchor: "AI TRADING ASSISTANT",
      heading: "AI trading assistant",
      text: "Your AI trading assistant works as your personal coach to the world of cryptocurrencies and trading. From reading candlestick charts to recommending the next stock, your AI assistant has got you covered.",
      effect:"fade-right"
   

    },
    {
      img: "assets/card2.svg",
      anchor: "AI crypto NFT CREATOR",
      heading: "AI NFT creator",
      text: "Creating new NFTs from the AI NFT creator provides you the liberty to bring your ideas to life without the hustle of programming it.",
      effect:"fade-right"
  
    },
    {
      img: "assets/card3.svg",
      anchor: "AI MUSIC CREATOR",
      heading: "AI Music creator",
      text: "The AI music creator write AI generated lyrics for your song composition. The lyrical content is unique to your composition that is not available anywhere on the internet. Enjoy your music without the fear of copyright issues.",
      effect:"fade-up"
   
    },
  ];
  return (
    <div className='container-fluid sec1-bg'>
      <div className='container'>
        <div className='row mt-3'>
          <div className='col-md-12 '>
            <div className='text-center'>
              <video
                data-aos='zoom-in'
                playsInline
                autoPlay
                muted
                loop
                className='m-auto res-video'
                style={{ height: "330px" }}>
                <source
                  src={process.env.REACT_APP_S3_URL + "/fXquDiH.mp4"}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div
          className='row mt-5'
          style={{ maxWidth: "1000px", margin: "auto" }}>
          {data.map((v, i) => {
            return (
              <div className='col-md-6 mt-4 col-12' key={i}>
                <div className='card m-auto'  data-aos={v.effect}>
                  <img src={v.img} alt='' />
                  <div className='card-body'>
                    <a href='/'>
                      {v.anchor} <BsArrowRight />
                    </a>
                    <h1>{v.heading}</h1>
                    <p>{v.text}</p>
                    <button>
                      <span>Read more</span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Section1;
