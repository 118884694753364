/** @format */

import React from "react";
import Faq from "react-faq-component";

const data = {
  //   title: "FAQ (How it works)",
  rows: [
    {
      title: "Can I use AI2 tool for placing my trades?",
      content: `Yes, you can use the automated trade feature to place trades on your behalf.`,
    },
    {
      title: "I’m an emotional buyer, Can Ai2 help me?",
      content: `If you are an emotional investor, this tool is best for you, since AI2 eliminates emotional influence on trades and only prefer data-driven technical trades.`,
    },
    {
      title: "Can I access AI2 tool information from my mobile?",
      content: `Yes you can access the information from your web interface as well as the mobile app.`,
    },
    {
      title: "I hate to sit and watch the screen while trading, can you help?",
      content: `AI2 has an inherent risk management tool in its interface. You can use this feature to automate your TP and SL.`,
    },
    {
      title: "Can I get personal recommendations, I don’t know how to trade?",
      content: `Yes you can get personal trading recommendations from our bot.`,
    },
    // {
    //   title: "",
    //   content: ``,
    // },

  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "#EBEEF2",
  rowTitleColor: "#EBEEF2",
  rowContentColor: "#A5ABB3",
  arrowColor: "#FFF",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingLeft: "30px",
  rowContentPaddingRight: "30px",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true
  margin: "2px",
};

export default function Faqs() {
  return (
    <div className='container section-padding' id="Faqs">
      <div className='row res-footer display-8'>
        <div
          className='col-md-12 blockmover-row2 text-center'
          data-aos='fade-up'
          data-aos-duration='1500'>
          <h1>FAQ's</h1>
          <p>Frequently Asked Question</p>
        </div>

        <div data-aos='fade-right' data-aos-delay='500'>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </div>
  );
}
