/** @format */

import React from "react";
import About from "../components/elements/About";
import Hero from "../components/elements/Hero";
import Roadmap from "../components/elements/Roadmap";
import Section1 from "../components/elements/Section1";
import Section2 from "../components/elements/Section2";
import Timeline from "../components/elements/Timeline";
import Tokonomics from "../components/elements/Tokonomics";
import Faqs from "../components/utils/Faqs";

function Home() {
  return (
    <>
      <Hero />
      <Section1 />
      <Section2 />
      <Tokonomics />
      <Timeline />
      {/* <Roadmap /> */}
      <About />
      <Faqs/>
    </>
  );
}

export default Home;
